@import 'application';

.follow-requests-reminder {
  text-align: right;
  padding: 10px;
  .follow-requests-link {
    color: $highlight-text-color;
  }
}

.fc-never-ask {
  font-size: 0.8em;
  margin-top: 5px;
}

.fc-audio {
  max-width: 100%;
}
